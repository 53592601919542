import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_it-it",
  "shopName": "lusini",
  "locale": "it-it",
  "rootCategoryId": "EMG",
  "orderMinimumB2C": 25,
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/helpandservice/contatti/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "it-IT",
    "country": "IT"
  },
  "slugifyLocale": "it",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "it-it",
      "hrefLang": "it"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-it.svg",
      "newsletter_intentlayer_picture": "https://res.cloudinary.com/lusini/image/upload/v1724665736/application/NewsletterPopupAB/discount-reduction-it-it.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCKXUGUW8DWDBCN5R3VHRU9D",
        "b2c": "SWSCOZY2IBWKFJSFB70499X1LH"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/it-it/b2b",
        "b2c": "https://www.lusini.com/it-it/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "e3241f26e68894b7351374710059355e2181541cd5ef10353a15507d5d1e859b",
      "campaignID": "9879543"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "X42D9BEF43879C7FA3F7F0063749DEA65",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": "WXpnNU5ETmhZV0l0WWpVMU5pMDBOakV5TFdKa05HWXRZemRoWTJaa01qQXlOVE0x"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_it_IT_products",
    "productsPriceAsc": "prod_lusini_it_IT_products_priceAsc",
    "categories": "prod_lusini_it_IT_categories",
    "series": "prod_lusini_it_IT_series",
    "productsSuggestion": "prod_lusini_it_IT_products_query_suggestions",
    "productsLatest": "prod_lusini_it_IT_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Bianco": "white",
    "Nero": "black",
    "Grigio": "grey",
    "Argento": "silver",
    "Marrone": "brown",
    "Blu": "blue",
    "Multicolore": "multicolor",
    "Rosso": "red",
    "Beige": "beige",
    "Verde": "green",
    "Transparente": "transparent",
    "Giallo": "yellow",
    "oro": "gold",
    "Arancione": "orange",
    "Rosa/Lilla": "lilac"
  },
  "features": {
    "showNewsletterIntentLayer": true,
    "leadinfo": true,
    "creditCheck": false,
    "devMode": false,
    "trustShop": true,
    "zenloop": true,
    "b2c": true,
    "userLikeWidgetUrl": "",
    "notificationBar": true,
    "customizableUrl": "https://www.lusini.com/it-it/helpandservice/servizi/personalizzazioni/",
    "showCartInfoboxFrom": "2023-12-13T08:00:00",
    "showCartInfoboxTo": "2024-06-14T01:00:00"
  },
  "abTests": {}
}
export default config